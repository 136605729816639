export const DESIGNS = [
    {
        name: "Jared's Living Room Design",
        _id: "66f1a552cce56d000d553507",
        img: "v1728495803/server/production/server/designs/render/6706c0bbefbf54000d830daf.jpg",
    },
    {
        name: "Andrew's Living Room Design",
        _id: "66e9ad766040d6000d646bef",
        img: "v1727986229/server/production/server/designs/render/66fefa343bc5f8000d80f23d.png",
    },
    {
        name: "Michelle large Spaces",
        _id: "66f1a007cce56d000d54cbfa",
        img: "v1727264827/server/production/server/designs/render/66f3f83b9f1269000dc97dcb.jpg",
    },
    {
        name: "Adam's Living Room Design",
        _id: "66e84eccec53bf000d4d9109",
        img: "v1726564523/server/production/server/designs/render/66e948ab97b3ec000d8f226a.jpg",
    },
    {
        name: "Kristy's Bedroom Design",
        _id: "66da096cd30264000d6a615b",
        img: "v1726111321/server/production/server/designs/render/66e255ffbdf5bb000d5f3ff9.jpg"
    },
    {
        name: "Raza's office design",
        _id: "67019db1569f74000db841f7",
        img: "v1728927509/server/production/server/designs/render/670d5715315bd0000d070bea.jpg"
    },
    
]