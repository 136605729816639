import { DESIGNS } from "./mock";
import { CLOUDINARY_PREFIX } from "./constants";

const container = document.getElementById("RoomCardContainer");

const createRoomCard = (design) => {
  const roomCardContainer = document.createElement("div");
  roomCardContainer.classList.add("RoomChoices");

  const roomImg = document.createElement("img");
  roomImg.src = `${CLOUDINARY_PREFIX}/${design.img}`;
  roomImg.alt = design.name;

  const roomCard = document.createElement("div");
  roomCard.classList.add("room-card");
  roomCard.textContent = design.name;
  roomCard.setAttribute("data-design-id", design._id);

  roomCardContainer.appendChild(roomImg);
  roomCardContainer.appendChild(roomCard);

  roomCardContainer.addEventListener("click", () => {
    window.location.href = `/design/${design._id}`;
  });

  return roomCardContainer;
};

const renderRoomCards = (designs) => {
  const RoomSelection = document.getElementById("RoomSelection");
  designs.forEach((design) => {
    const roomCard = createRoomCard(design);
    RoomSelection.appendChild(roomCard);
  });
};

const init = () => {
  const RoomSelection = document.createElement("div");
  RoomSelection.id = "RoomSelection";
  container.appendChild(RoomSelection);

  renderRoomCards(DESIGNS);
};

init();
