export const SKYBOX_ARRAY = [
  "/skyboxFiles/1a9a5b60-cdb0-4295-a972-3d256298e082-spiaggia_di_mondello_2k.jpeg",
  "/skyboxFiles/bbd46739-addc-423e-9f62-7cc63574e3b8-Thumersbach_low.jpg",
  "/skyboxFiles/15578edb-24ce-4848-ab2f-cd2845007695-blue_lagoon_night_1k.jpeg",
  "/skyboxFiles/73041d8a-ce5d-4e1f-b327-fb49fd77cfb2-limpopo_golf_course_2k.jpeg",
];

export const BASE_API_URL = "https://api.joyverse.club";
export const SPACEJOY_BASE_API_URL = "https://api.spacejoy.com";
export const DUMMY_TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoibWFuYXYga2F1c2hhbCIsImVtYWlsIjoibWFuYXYua2F1c2hhbEBzcGFjZWpveS5jb20iLCJyb2xlIjoiYWRtaW4iLCJhdXRoVHlwZSI6InBhc3N3b3JkIiwiX2lkIjoiNjMxODQ5ODUyZTM2NDIwMDFlOTIzNDU3IiwiZ2VuZGVyIjoibWFsZSIsInVzZXJuYW1lIjoibWFuYXYta2F1c2hhbCIsImlhdCI6MTcyNTU1NzY2MH0.iJeWmShNFejqLL2e-Phk7u4JzNUOZful2WvMrt7pk2E";
export const FILE_PREFIX = "https://file.joyverse.club";

export const SPACEJOY_DUMMY_TOKEN =
  "JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVkYTg4M2IzYWZkNzBhYTFiODAwYjE1YSIsIl9pZCI6IjVkYTg4M2IzYWZkNzBhYTFiODAwYjE1YSIsIm5hbWUiOiJBZG1pbiIsImVtYWlsIjoiYWRtaW5Ac3BhY2Vqb3kuY29tIiwicm9sZSI6Im93bmVyIiwiY3JlZGl0cyI6MCwic3RhdHVzIjoiYWN0aXZlIiwidG5jIjpmYWxzZSwicGhvbmUiOm51bGwsInRyaWFsRXhoYXVzdGVkIjpmYWxzZSwiaWF0IjoxNzI5NDk5MTQ2LCJleHAiOjE3MzgxMzkxNDZ9.3-5RVUdvQgnSclA3HqCywkKpombYU_SsvTqfpwtWdVk";

export const CLOUDINARY_PREFIX =
  "https://res.cloudinary.com/spacejoy/image/upload/f_auto,q_auto/w_300,ar_1.82,c_fill";

export const LovelyQuotes = [
  "Home is where our story begins…",
  "Home is the starting place of love, hope and dreams.",
  "Home is not a place…it’s a feeling.",
  "There’s no place like home.",
  "The best journey takes you home.",
  "Where thou art, that is home.",
];
